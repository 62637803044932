
import React, { useEffect, useState } from 'react';
import CustomerHeader from '../CustomerHeader';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faShoppingBasket, faSearch } from '@fortawesome/free-solid-svg-icons';

import './ProductList.css';
import '../../../App.css';
import '../Customer.css';

const Cart = () => {
  const [cartItems, setCartItems] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('cart')) || [];
    } catch (error) {
      console.error('Error parsing cart items:', error);
      return [];
    }
  });

  useEffect(() => {
    // Update localStorage when cartItems change
    localStorage.setItem('cart', JSON.stringify(cartItems));
  }, [cartItems]);

  const handlePriceCalculation = (item) => {
    if (item.price_by_discount) {
      if (item.is_discount_by_price) {
        return item.quantity * item.price_by_discount;
      } else if (item.is_discount_by_quantity && item.discount_by_quantity) {
        if (item.discount_by_quantity <= item.quantity) {
          return item.quantity * item.price_by_discount;
        } else {
          return item.quantity * item.price;
        }
      } else {
        return item.quantity * item.price;
      }
    } else {
      return item.quantity * item.price;
    }
  }

  const totalAmount = cartItems.reduce((total, item) => total + handlePriceCalculation(item), 0);

  const clearCart = () => {
    localStorage.clear();
    setCartItems([]);
  }

  const handleAddToCart = (item_id) => {
    // Find the item in the cart
    const existingItem = cartItems.find(item => item.pk === item_id);

    if (existingItem) {
      // If the item is already in the cart, update the quantity
      const updatedCart = cartItems.map(item =>
        item.pk === item_id ? { ...item, quantity: item.quantity + 1 } : item
      );
      setCartItems(updatedCart);
    } 
    // else {
    //   // If the item is not in the cart, add it with quantity 1
    //   const newItem = { ...item, quantity: 1 };
    //   setCartItems([...cartItems, newItem]);
    // }
  }

  const handleRemoveFromCart = (item_id) => {
    // Find the item in the cart
    const existingItem = cartItems.find(item => item.pk === item_id);

    if (existingItem && existingItem.quantity > 1) {
      // If the item is in the cart and has a quantity greater than 1, decrement the quantity
      const updatedCart = cartItems.map(item =>
        item.pk === item_id ? { ...item, quantity: item.quantity - 1 } : item
      );
      setCartItems(updatedCart);
    } else {
      // If the item is in the cart and has a quantity of 1, remove it from the cart
      const updatedCart = cartItems.filter(item => item.pk !== item_id);
      setCartItems(updatedCart);
    }
  }

  return (
    <div>
      {console.log('rrr', cartItems)}
      <CustomerHeader />
      <div style={{display: '', textAlign: "center", maxWidth: '800px', margin: "0 auto",}}>
        <Button className='btn btn-danger' style={{height: '40px', float: 'right', marginRight: '20px'}} onClick={clearCart}>Очистить Корзинку</Button>
        <div style={{paddingTop: '50px'}}>
        {cartItems.map(item => (
                  <div style={{display: "flex", paddingBottom: '10px', marginBottom: "10px", borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: 'rgb(231, 231, 231)'}} 
                    key={item.pk}>
                  <div style={{float: "left", marginLeft:"10px"}}>
                    <a href={`product-detail/${item.pk}`}>
                    <img className="product-image" 
                        style={{height: "138px", width: "138px", borderRadius: "5%"}} 
                        src={item.image}
                        alt={item.name}
                    />
                    </a>
                    </div>
                  <div className="product-details" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div className="product-description" style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                      {item.description}
                      </div>

                    <div style={{ display: '', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                      <div className="product-price" style={{ whiteSpace: 'pre-wrap'}}>
                        <span className="color-green">{handlePriceCalculation(item)}тг</span>
                      </div>

                  {/* Simplify the Add/Remove button logic */}
                  <div className="d-flex" style={{display: 'flex', alignItems: 'center', fontSize: '25px'}}>
                      <FontAwesomeIcon icon={faPlus} onClick={() => handleAddToCart(item.pk)} className='plus-minus'/>
                      <div>
                        <span style={{marginRight: "10px", marginLeft: "10px", userSelect: 'none'}}> {item.quantity || 0} </span>
                        <FontAwesomeIcon onClick={() => handleRemoveFromCart(item.pk)} icon={faMinus} className='plus-minus'/>
                      </div>
                  </div>

                </div>
                  </div>
                </div>
        ))}
        <h4>Итого:   {totalAmount}</h4>
        </div>

        {totalAmount > 0 ?
          (<Link to="/make-order">
            <Button style={{backgroundColor: '#368B1C'}}>
              Заказать
            </Button>
          </Link>) : null
        }

        </div>
        <footer style={{height: '20px'}}>

        </footer>
      </div>
  );
};

export default Cart;
