import React, { Component } from 'react'
import CustomerHeader from '../CustomerHeader'
import axios from 'axios';
import { API_CUSTOMER } from '../../../hook';

export default class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        orders: [],
    }
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData() {
    axios.get(`${API_CUSTOMER}/user-orders/`)
    .then(response => {
        this.setState({orders: response.data });
    }).catch(error => {
        console.error('failed when get orderlist: ', error);
    });
  }

  getStatusLabel = (status) => {
    const statusMap = {
      'REQUESTED': 'Запрошено',
      'ACCEPTED': 'Принято',
      'REJECTED': 'Отказано',
      'COMPLETED': 'Завершено',
    };
    return statusMap[status] || status;
  };

  render() {
    const { orders } = this.state;
    return (
      <>
      <CustomerHeader />
      <div style={{margin: '0 auto', textAlign: 'center', maxWidth: '800px', paddingLeft: '10px'}}>
        <h3>Список заказов</h3>
        {!orders || orders.length <= 0 ? (
              <div>
                <div colSpan="6" align="center">
                  <b>Пусто</b>
                </div>
              </div>
            ) : (
              orders.map((order) => (
                <div style={{textAlign: 'start'}} key={order.pk}>
                  <div>
                    <div>Покупатель: {order.customer_name}</div>
                    <div>Дата: {new Date(order.date_updated).toLocaleString('en-GB', { hour12: false, hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: 'numeric' })}</div>
                    <div>Номер телефона: {order.mobile_number}</div>
                    {order.comment_for_order && (
                      <div>Комментарий к заказу: {order.comment_for_order}</div>
                    )}
                    <div>Адрес доставки: {order.address_for_order}</div>
                    {order.comment_for_delivery && (
                      <div>Комментарий к доставке: {order.comment_for_delivery}</div>
                    )}
                    <div>Статус: {this.getStatusLabel(order.status)}</div>
                    <hr style={{color: "red", fontWeight: "bold"}}/>
                    <div>
                      {order.items.map((item) => (
                        <div key={item.pk}>
                          <div>Наименование: {item.name}</div>
                          <div>Описание: {item.description}</div>
                          <div>Cумма: {item.price_per_unit} x {item.quantity} = {item.price_per_unit*item.quantity}</div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </div>
                  <h5 style={{fontWeight: "bold"}}>Итог: {order.total_price}</h5>
                  <hr style={{ borderTop: '5px solid red', fontWeight: 'bold' }}/>
                </div>
              ))
            )}
      </div>
      </>
    )
  }
}
