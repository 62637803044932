import React, { Component } from 'react';
import { Table } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import './Order.css';
import { API_ADMIN } from '../../../hook';
import axios from 'axios';

export default class OrderList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedColumn: 'requested',
      orders: [],
      loading: false,
      pageCount: 0,
      currentPage: 0,
    };
  }

  componentDidMount() {
    // this.requestedOrders();

    this.setState({ loading: false }, () => {
      // Add a small delay before fetching data
      setTimeout(() => {
        this.fetchOrders('requested', 0);
      }, 100); // Adjust the delay as needed
    });
  }

  fetchOrders = async (status, page) => {
    this.setState({ loading: true });

    try {
      const response = await axios.get(`${API_ADMIN}/${status}-order/?page=${page + 1}`);
      const { results, count, next, previous } = response.data;

      this.setState({
        orders: results,
        loading: false,
        pageCount: Math.ceil(count / 10), // Adjust the page size if needed
        currentPage: page,
        selectedColumn: status,
      });
    } catch (error) {
      console.error(`Error fetching list orders (${status}) on administrator page:`, error);
      this.setState({ loading: false });
    }
  };

  handlePageClick = (selectedPage) => {
    this.fetchOrders(this.state.selectedColumn, selectedPage.selected);
  };

  handleChangeStatus = (orderId, newStatus) => {
    // Update the local state optimistically
    this.setState((prevState) => ({
      orders: prevState.orders.map((order) => {
        if (order.pk === orderId) {
          return { ...order, status: newStatus };
        }
        return order;
      }),
    }));

    // Send a PATCH request to update the status on the server
    axios
      .patch(`${API_ADMIN}/order/${orderId}`, { status: newStatus })
      .then((response) => {
        console.log(response.data);
        // Optionally, you can update the local state with the updated order object
        // if the backend response includes the updated order object.
        this.fetchOrders();
        // this.props.toggle();
      })
      .catch((error) => {
        console.error('Error:', error);
        // Rollback the local state to the previous status on error
        this.requestedOrders();
        // this.props.toggle();
      });
  };

  render() {
    const { selectedColumn, orders, loading, pageCount, currentPage } = this.state;

    // if (this.state.loading) {
    //   return <p>Загружается...</p>;
    // }
  
    // // Handle the case when orders is not available
    // if (!this.state.orders) {
    //   return <p>Список заказов пуст</p>;
    // }

    return (
      <div>
        <h2 style={{ textAlign: 'center' }}>Заказы</h2>
        {loading ? (
          <p>Загружается...</p>
        ): (
        <div>
        <Table dark bordered striped hover>
          <thead>
            <tr style={{textAlign: 'center'}}>
                <th
                  onClick={() => this.fetchOrders('requested', 0)}
                  style={{ backgroundColor: selectedColumn === 'requested' ? 'red' : '' }}
                >
                  Запрошено
                </th>
                <th
                  onClick={() => this.fetchOrders('accepted', 0)}
                  style={{ backgroundColor: selectedColumn === 'accepted' ? 'red' : '' }}
                >
                  Принято
                </th>
                <th
                  onClick={() => this.fetchOrders('completed', 0)}
                  style={{ backgroundColor: selectedColumn === 'completed' ? 'red' : '' }}
                >
                  Завершено
                </th>
                <th
                  onClick={() => this.fetchOrders('rejected', 0)}
                  style={{ backgroundColor: selectedColumn === 'rejected' ? 'red' : '' }}
                >
                  Отказано
                </th>
              </tr>
          </thead>
        </Table>
        <div style={{maxWidth: '800px', margin: '0 auto'}}>
            {!orders || orders.length <= 0 ? (
              <div>
                <div colSpan="6" align="center">
                  <b>Пусто</b>
                </div>
              </div>
            ) : (
              orders.map((order, index) => (
                <div key={order.pk} style={{backgroundColor: index%2==1 ? '#e0ffaf' : '#fff9af', padding: '10px'}}>
                  <div>
                    <div style={{fontWeight: '600' }}>
                      <div>Покупатель: {order.customer_name}</div>
                      <div>Дата последнее измение: {new Date(order.date_updated).toLocaleString('en-GB', { hour12: false, hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: 'numeric' })}</div>
                      <div>Дата заказ запроса: {new Date(order.date_created).toLocaleString('en-GB', { hour12: false, hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: 'numeric' })}</div>
                      <div>Номер телефона: {order.mobile_number}</div>
                      {order.comment_for_order && (
                        <div>Комментарий к заказу: {order.comment_for_order}</div>
                      )}
                      <div>Адрес доставки: {order.address_for_order}</div>
                      {order.comment_for_delivery && (
                        <div>Комментарий к доставке: {order.comment_for_delivery}</div>
                      )}
                    </div>

                    <div>
                      {/* Dropdown for changing status */}
                      <select
                        value={order.status}
                        onChange={(e) => this.handleChangeStatus(order.pk, e.target.value)}
                      >
                    {(() => {
                      if (order.status === 'REQUESTED') {
                        return (
                          <>
                            <option value="REQUESTED">Изменить статус</option>
                            <option value="ACCEPTED">Принять</option>
                            <option value="REJECTED">Отказать</option>
                          </>
                        );
                      } else if (order.status === 'ACCEPTED') {
                        return (
                          <>
                            <option value="ACCEPTED">Изменить статус</option>
                            <option value="COMPLETED">Завершить</option>
                            <option value="REJECTED">Отказать</option>
                          </>
                        );
                      } else {}
                    })()}
                      </select>
                    </div>
                    <h5 style={{paddingTop:'10px'}}>Список продукт</h5>
                    <hr />
                    <div>
                      {order.items.map((item) => (
                        <div key={item.pk} >
                          <div>Наименование: {item.name}</div>
                          <div>Описание: {item.description}</div>
                          <div>Cумма: {item.price_per_unit} x {item.quantity} = {item.price_per_unit*item.quantity}</div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </div>
                  <h5 style={{fontWeight: "bold"}}>Итого: {order.total_price}</h5>
                  <hr style={{ borderTop: '5px solid red', fontWeight: 'bold' }}/>
                </div>
              ))
            )}
          </div>
                    
          <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
          <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={currentPage}
              breakLabel={'...'}
              breakClassName={'break-me'}
              disableInitialCallback={true} // To prevent the callback on initial render
              pageLinkClassName={'page-link custom-page-link'} // Add your custom class
              previousLinkClassName={'page-link custom-previous-link'} // Add your custom class
              nextLinkClassName={'page-link custom-next-link'} // Add your custom class
              pageClassName={'page-item'} // Remove custom class
              previousClassName={'page-item'} // Remove custom class
              nextClassName={'page-item'} // Remove custom class
            />
          </div>

        </div>
        )}
      </div>
    );
  }
}
