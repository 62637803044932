import axios from 'axios';
import React, { Component } from 'react'
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { API_ADMIN } from '../../../hook';

export default class ProductForm extends Component {
    product_url = `${API_ADMIN}/${this.props.product_pk}/product/`

    constructor(props) {
      super(props);
      this.editProduct = this.editProduct.bind(this);
    } 
    
    state = {
        pk: 0,
        name: "",
        image: null,
        description: "",
        price: null,
        category: this.props.product_pk
    };
   
    componentDidMount() {
      if (this.props.product) {
        const { pk, name, description, price } = this.props.product;
        this.setState({ pk, name, description, price });
      }
    }
  
    onChange = e => {
      this.setState({ [e.target.name]: e.target.value });
    };

  handleFileChange = (e) => {
    this.setState({ image: e.target.files[0] });
  };
  
    createProduct = e => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("name", this.state.name);
      formData.append("image", this.state.image);
      formData.append("description", this.state.description);
      formData.append("price", this.state.price);
      formData.append("category", this.state.category);
      axios.post(this.product_url, formData).then(() => {
        this.props.resetState();
        this.props.toggle();
      });
    };
  
    editProduct = e => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("name", this.state.name);
      // formData.append("image", this.state.image);
      formData.append("description", this.state.description);
      formData.append("price", this.state.price);
      formData.append("category", this.state.category);

      // Check if an image is selected before appending it to formData
      if (this.state.image) {
        formData.append("image", this.state.image);
      }
      // else {
      //   formData.append("image", this.state.image);
      // }

      axios.patch(this.product_url + this.state.pk, formData)
      .then(() => {
        this.props.resetState();
        this.props.toggle();
      })
      .catch((error) => {
        console.error("Error making discount on administrator page:", error);
      });
    };
  
    defaultIfEmpty = value => {
      return value === "" ? "" : value;
    };
  render() {
    return (
      <Form onSubmit={this.props.product ? this.editProduct : this.createProduct}>
        <FormGroup>
          <Label for="name">Наименование:</Label>
          <Input
            type="text"
            name="name"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.name)}
            required
          />
        </FormGroup>
          <FormGroup>
            <Label for="name">Картинка:</Label>
            <Input
              type="file"
              name="image"
              accept="image/*"
              content="multipart/form-data"
              onChange={this.handleFileChange}
              required={!this.props.product}
            />
          </FormGroup>
        <FormGroup>
          <Label for="name">Описание:</Label>
          <Input
            type="text"
            name="description"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.description)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="name">Цена:</Label>
          <Input
            type="number"
            name="price"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.price)}
            required
          />
        </FormGroup>
        <Button>{this.props.product ? "Изменить" : "Создать"}</Button>
      </Form>
    )
  }
}
