import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../../../hook";
import { Label, Input, Button } from "reactstrap";

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const submit = async (e) => {
    e.preventDefault();

    const user = {
      username: username,
      password: password,
    };

    try {
      const { data } = await axios.post(`${API_URL}/token/`, user, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      localStorage.clear();
      localStorage.setItem('access_token', data.access);
      localStorage.setItem('refresh_token', data.refresh);
      axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;
      window.location.href = '/admin';
    } catch (error) {
      setError('Неправильный логин или пароль');
      setPassword(''); // Clear the password field
    }
  };

  return (
    <div>
      {/* <AdminHeader /> */}
      <form onSubmit={submit} style={{ maxWidth: "600px" }} className="mx-auto">
        <div>
          <h3 style={{ textAlign: "center" }}>Вход</h3>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <div className="form-group mt-3 mx-auto">
            <Label>Логин</Label>
            <Input
              className="form-control mt-1"
              placeholder="Логин"
              name="username"
              type="text"
              value={username}
              required
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <Label>Пароль</Label>
            <Input
              name="password"
              type="password"
              className="form-control mt-1"
              placeholder="Пароль"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <Button type="submit" className="btn btn-success mx-auto">
              Войти
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
