import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter } from "reactstrap";

import axios from "axios";
import { API_ADMIN } from "../../../hook";
 

export class CategoryRemove extends Component {
  state = {
    modal: false,
  };

componentDidMount() {
  if (this.props.category) {
    const { pk, name } = this.props.category;
    this.setState({ pk, name });
  }
}

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteCategory = pk => {
    axios.delete(`${API_ADMIN}/category/` + pk).then(() => {
      this.props.resetState();
      this.toggle();
    }).catch(error => {
      this.displayErrorMessage("В категории есть продукты, сначала освободите категорию");
    });
  };

  displayErrorMessage = message => {
    // You can use a modal library or a custom modal component to display the error message
    // For simplicity, this example uses the window.alert function
    window.alert(message);
    // You can replace window.alert with a more sophisticated modal implementation if needed
  };

  render() {
    return (
      <Fragment>
        <Button color="danger" onClick={() => this.toggle()}>
          Удалить
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Удалить {this.props.name}?
          </ModalHeader>

          <ModalFooter>
            <Button type="button" onClick={() => this.toggle()}>
              Отказать
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={() => this.deleteCategory(this.props.pk)}
            >
              Да
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
