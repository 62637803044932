import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import axios from 'axios';
import { API_CUSTOMER } from '../../hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './Customer.css';
import './CustomerHeader.css';
import whats from '../../assets/whats.png';
export default function CustomerHeader () {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userData, setUserData] = useState(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    // Axios GET request to fetch user data
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_CUSTOMER}/user-info/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        setUserData(response.data); // Assuming the response contains user data
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, []);

  // Get the current hour
  const currentHour = new Date().getHours();

  // Determine whether it's outside of work hours (23:00-8:00)
  const isOutsideWorkHours = currentHour < 8 || currentHour >= 23;

  const dropdownHeight = dropdownOpen ? "200px" : "100px";
  const transitionStyle = {
    height: dropdownHeight,
    transition: "height 0.3s ease-in-out", // Adjust the duration and timing function as needed
  };

  return (
    <div style={{ 
      backgroundColor: "#fff", ...transitionStyle, overflowX: "auto", maxWidth: "800px", margin: "0 auto",
      }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{paddingTop: "10px"}}>
          <nav className="text-center" style={{whiteSpace: 'nowrap', display: 'flex' }}>
            <NavLink to="/" style={{ margin: '0 20px'}} activeClassName="active-link">
              <FontAwesomeIcon icon={faHome} className="color-green fa-2x" style={{color: '#368B1C'}} />
            </NavLink>
            <div style={{}}>
              <a href='https://wa.me/+77783361779'>
              <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#368B1C', width: '35px', height: '35px' }} />
              </a>
            </div>
          </nav>
        </div>

        <h1 style={{textAlign: "center", paddingTop: '10px'}}>SEOUL STATION</h1>

        <div style={{position: "relative", paddingTop: "10px", marginRight: "20px"}}>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} positionFixed>
              <DropdownToggle style={{backgroundColor: '#368B1C'}} caret>
                {userData?.username || 
                <FontAwesomeIcon icon={faUser} className='fa-lg'/>}
              </DropdownToggle>
              { userData?.username ? (
                <>
                <DropdownMenu style={{paddingRight: '10px'}}>
                  <NavLink to="/update/" className="dropdown-item" activeClassName="active-link">
                    Изменить данные
                  </NavLink>
                  <NavLink to="/order-list" className="dropdown-item" activeClassName="active-link">
                    Список заказов
                  </NavLink>
                  <NavLink to="/logout" className="dropdown-item" activeClassName="active-link">
                    Выйти
                  </NavLink>
                </DropdownMenu>
                </>
              ): (
                <>
                  <DropdownMenu style={{paddingRight: '10px'}}>
                    <NavLink to="/login" className="dropdown-item" activeClassName="active-link">
                      Войти
                    </NavLink>
                    <NavLink to="/register" className="dropdown-item" activeClassName="active-link">
                      Регистрироваться
                    </NavLink>
                </DropdownMenu>
                </>
              )}
            </Dropdown>
        </div>
      </div>

      {isOutsideWorkHours && (
        <div style={{ marginBottom: "10px", color: "green", textAlign: "center" }}>
          Мы свяжемся в рабочее время(8:00-23:00)
        </div>
      )}

      {/* <h1 style={{textAlign: "center", color: '#368B1C', marginTop: '0px', paddingTop: '0px', display: 'block', position: 'absolute'}}>Optovka</h1> */}
    </div>
  );
}
