import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { API_URL } from '../../hook';
import axios from 'axios';

class AdminHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
      loading: false,
      error: null,
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });

    try {
      const { data } = await axios.get(`${API_URL}/home/`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      this.setState({ username: data.username, loading: false });
    } catch (error) {
      console.error('Error fetching username:', error);
      this.setState({
        error: 'Unable to fetch username. Please check your internet connection and try again.',
        loading: false,
      });
    }
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    const { username, loading, dropdownOpen } = this.state;



    if (this.state.loading) {
      if (loading) {
        return <p>Loading...</p>;
      }
    }

    // if (loading) {
    //   return (
    //     <div style={{ textAlign: 'center', paddingTop: '20px' }}>
    //       <p>Загружается...</p>
    //       {/* You can use a loading spinner library or your custom spinner here */}
    //     </div>
    //   );
    // }
  
    // if (error) {
    //   return (
    //     <div style={{ textAlign: 'center', paddingTop: '20px' }}>
    //       <p>Ошибка: {error}</p>
    //     </div>
    //   );
    // }

    const gradient = {
      backgroundColor: 'red',
      backgroundImage: 'linear-gradient(to bottom right, red, yellow)',
      overflowX: 'auto'
    };

    return (
      <div style={{backgroundColor: 'rgb(33, 37, 41)', backgroundImage: 'linear-gradient(to bottom right, rgb(33, 37, 41), rgb(77, 81, 84))', overflowX: 'auto', height: '200px', margin: '0 auto', display: 'flex' }}>
        <nav className="text-center" style={{ paddingTop: '10px', whiteSpace: 'nowrap', display: 'flex', margin: '0 auto'}}>
          <NavLink to="/admin/" style={{ margin: '0 20px' }} activeClassName="active-link">
            <Button className='btn btn-danger'>Заказы</Button>
          </NavLink>
          <NavLink to="/admin/banner/" style={{ margin: '0 20px' }} activeClassName="active-link">
            <Button className='btn btn-danger'>Баннеры</Button>
          </NavLink>
          <NavLink to="/admin/category/" style={{ margin: '0 20px' }} activeClassName="active-link">
            <Button className='btn btn-danger'>Продукты</Button>
          </NavLink>
          <Dropdown style={{margin: '0 20px'}} isOpen={dropdownOpen} toggle={this.toggleDropdown}>
            <DropdownToggle className='btn btn-danger' caret>{username}</DropdownToggle>
            <DropdownMenu>
              {username === 'owner' ? (
                <>
                  <NavLink to="/owner/" className="dropdown-item" activeClassName="active-link">
                    Управление администраторов
                  </NavLink>
                  <NavLink to="/customer-list/" className="dropdown-item" activeClassName="active-link">
                    Cписок пользователей
                  </NavLink>
                </>
              ) : null}
              <NavLink to="/analytic-order/" className="dropdown-item" activeClassName="active-link">
                Аналитика продуктов
              </NavLink>
              <NavLink to="/a-logout/" className="dropdown-item" activeClassName="active-link">
                Выйти
              </NavLink>
            </DropdownMenu>
          </Dropdown>
        </nav>
      </div>
    );
  }
}

export default AdminHeader;
