import React, { Component } from "react";
import { Table } from "reactstrap";
import { ProductRemove } from "./ProductRemove";
import NewProductModal from "./NewProductModal";
import DiscountProduct from "./DiscountProduct";

class ProductList extends Component {
  render() {
    const products = this.props.products;
    return (
      <Table dark bordered hover striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>Наименование</th>
            <th>Картинка</th>
            <th>Описание</th>
            <th>Цена</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!products || products.length <= 0 ? (
            <tr>
              <td colSpan="6" align="center">
                <b>Пусто</b>
              </td>
            </tr>
          ) : (
            products.map((product, index) => (
              <tr key={product.pk}>
                <td>{index+1}</td>
                <td>{product.name}</td>
                <td><img src={product.image} style={{width:"100px", height:"100px"}} alt={product.name}/></td>
                <td>{product.description}</td>
                <td>{product.price}</td>
                <td align="center">
                  <DiscountProduct
                    product={product}
                    product_pk={this.props.product_pk}
                    resetState={this.props.resetState}
                  />
                  &nbsp;&nbsp;
                  <NewProductModal
                    create={false}
                    product={product}
                    product_pk={this.props.product_pk}
                    resetState={this.props.resetState}
                  />
                  &nbsp;&nbsp;
                  <ProductRemove
                    pk={product.pk}
                    name={product.name}
                    product_pk={this.props.product_pk}
                    resetState={this.props.resetState}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    );
  }
}

export default ProductList;