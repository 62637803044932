import React, { useEffect, useState } from 'react';
import { API_CUSTOMER } from '../../../hook'
import axios from 'axios';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import CustomerHeader from '../CustomerHeader';
import '../Customer.css';

const OrderForm = () => {
  const [formData, setFormData] = useState({
    customer_name: '',
    mobile_number: '',
    comment_for_order: '',
    address_for_order: '',
    comment_for_delivery: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(`${API_CUSTOMER}/update/`);

        setFormData({
          customer_name: userResponse.data?.customer_name || '',
          mobile_number: userResponse.data?.mobile_number || '',
          address_for_order: userResponse.data?.address_for_order || '',
          comment_for_order: userResponse.data?.comment_for_order || '',
          comment_for_delivery: userResponse.data?.comment_for_delivery || '',
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures that this effect runs only once on component mount

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const clearMessages = () => {
    // setSuccessMessage('');
    setErrorMessage('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setFormData({ ...formData, [name]: value });
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value === '' ? null : value,
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.address_for_order) {
      setErrorMessage('Пожалуйста, заполните обязательное поле: Имя, Телефон, Адрес');
      return;
    }

    try {
      const cart = localStorage.getItem('cart');
    
      // Parse the 'cart' data from localStorage
      const parsedCart = JSON.parse(cart);
  
      // Extract 'items' array from parsedCart
      const items = parsedCart.map(item => ({
        product: item.pk,
        quantity: item.quantity,
      }));
      
      const orderData = {
        ...formData,
        items,
      };

      // Make a POST request using Axios
      await axios.post(`${API_CUSTOMER}/make-order/`, orderData);
      localStorage.clear();
      setSuccessMessage('Ваш заказ принят!');
      setFormData({
        customer_name: '',
        mobile_number: '',
        comment_for_order: '',
        address_for_order: '',
        comment_for_delivery: '',
      });
      setTimeout(clearMessages, 10000);
    } catch (error) {
      // Handle errors
      console.error('Error submitting form:', error);
    }
  };

  setTimeout(() => {
    setSuccessMessage('');
  }, 10000);

  const handleInvalid = (e) => {
    e.preventDefault();
    setErrorMessage('Пожалуйста, заполните обязательное поле: Имя, Телефон, Адрес');
  };

  return (
    <>
      <CustomerHeader />
      <div style={{textAlign: "center", marginLeft: '10px', marginRight: '10px'}}>
        <h2>Оформление заказа</h2>
        <Form onSubmit={handleSubmit}>  
          {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          <div style={{maxWidth: "600px", textAlign: "center", margin: "0 auto"}}>
            <FormGroup style={{textAlign: "center"}}>
              <Label>Имя:</Label>
              <Input 
                className='order-path' 
                type="text" name="customer_name" 
                value={formData.customer_name} 
                onChange={handleChange} 
                title="запоните имя" 
                placeholder='Имя!' 
                onInvalid={handleInvalid}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Номер телефона:</Label>
              <Input 
                className='order-path' 
                type="text" 
                name="mobile_number" 
                value={formData.mobile_number} 
                onChange={handleChange} 
                title="запоните номер телефона" 
                placeholder='Номер телефона!' 
                onInvalid={handleInvalid} 
                required
              />
            </FormGroup>
            
            <FormGroup>
              <Label>Адрес доставки:</Label>
              <Input 
                className='order-path' 
                type="text" 
                name="address_for_order" 
                value={formData.address_for_order} 
                onChange={handleChange} 
                title="запоните номер телефона" 
                placeholder='Адрес доставки!' 
                onInvalid={handleInvalid} 
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Комментарий к заказу:</Label>
              <Input 
                className='order-path' 
                name="comment_for_order" 
                value={formData.comment_for_order} 
                onChange={handleChange} 
                placeholder='Комментарий к заказу'
              />
            </FormGroup>

            <FormGroup>
              <Label>Комментарий к доставке:</Label>
              <Input 
                className='order-path' 
                name="comment_for_delivery" 
                value={formData.comment_for_delivery} 
                onChange={handleChange} 
                placeholder='Комментарий к доставке'
              />
            </FormGroup>
          </div>
          <Button style={{backgroundColor: '#368B1C'}}>Оформить Заказ</Button>
        </Form>
      </div>
    </>
  );
};

export default OrderForm;