import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../hook';
import { Button, Table } from 'reactstrap';

class AdministratorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      administrators: [],
      loading: true,
    };
  }

  componentDidMount() {
    // this.fetchData();
    this.setState({ loading: false }, () => {
      // Add a small delay before fetching data
      setTimeout(() => {
        this.fetchData();
      }, 100); // Adjust the delay as needed
    });
  }

  fetchData = async () => {
    try {
        this.setState({ loading: true, error: null });
        const response = await axios.get(`${API_URL}/administrator-list/`);
    
        // Check if the response data is not undefined before updating the state
        if (response.data !== undefined) {
          this.setState({ administrators: response.data });
        }
      } catch (error) {
        console.error('Error fetching administrators:', error);
        this.setState({ error: 'Failed to fetch administrators. Please try again.' });
      } finally {
        this.setState({ loading: false });
      }
  };

  handleDelete = async (administratorId) => {
    try {
      if (administratorId === undefined || administratorId === null) {
        console.error('Invalid administratorId:', administratorId);
        return;
      }
  
      await axios.delete(`${API_URL}/delete-administrator/${administratorId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      this.fetchData();
    } catch (error) {
      console.error('Error deleting administrator:', error);
      this.setState({ error: 'Failed to delete administrator. Please try again.' });
    }
  };
  

  render() {
    const { administrators, loading } = this.state;

    return (
      <div style={{textAlign: "center"}}>
        <h1>Список администраторов</h1>
        {loading ? (
          <p>Загружается...</p>
        ) : (
          <Table dark bordered hover striped style={{maxWidth: "800px"}} className='mx-auto'>
            <tbody>
            {administrators.map((administrator) => (
              <tr key={administrator.pk}>
                <td style={{width: '70%'}} >{administrator.username}</td>
                <td><Button className='btn-danger' onClick={() => this.handleDelete(administrator.pk)}>Удалить</Button></td>
              </tr>
            ))}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}

export default AdministratorList;
