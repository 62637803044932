import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_CUSTOMER } from '../../../hook';
import { Button, Input, Label } from 'reactstrap';
import CustomerHeader from '../CustomerHeader';

const CustomerRegistrationForm = () => {
  // State for form fields
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    customer_name: '',
    mobile_number: '',
    address_for_order: '',
    comment_for_order: '',
    comment_for_delivery: '',
  });

  // State for success message
  const [registrationSuccess, setRegistrationSuccess] = useState(null);

  // State for registration error
  const [registrationError, setRegistrationError] = useState(null);

  const [usernameExists, setUsernameExists] = useState(false);

//   // Handle form field changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

  useEffect(() => {
    const checkUsernameAvailability = async () => {
      try {
        const response = await axios.get(`${API_CUSTOMER}/check-username/?username=${formData.username}`);
        setUsernameExists(response.data.exists);
      } catch (error) {
        console.error('Error checking username availability:', error);
      }
    };

    if (formData.username) {
      checkUsernameAvailability();
    }
  }, [formData.username]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        // Check if the username already exists
      if (usernameExists) {
        setRegistrationError('Это имя пользователя уже занято. Пожалуйста, выберите другой.');
        return;
      }

      // Make a POST request to your Django backend
      await axios.post(`${API_CUSTOMER}/register/`, formData);

      setRegistrationSuccess('Вы зарегистрировались!');
      setFormData({
        username: '',
        password: '',
        customer_name: '',
        mobile_number: '',
        address_for_order: '',
        comment_for_order: '',
        comment_for_delivery: '',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      setTimeout(() => {
        setRegistrationSuccess(null);
        setRegistrationError(null);
      }, 5000);
    } catch (error) {
// Handle registration error
      setRegistrationError('Ошибка при создании пользователя');
    }
  };

  return (
    <div style={{textAlign: "center"}}>
      <CustomerHeader />
      {registrationSuccess && <p style={{ color: 'green' }}>{registrationSuccess}</p>}
      {registrationError && <p style={{ color: 'red' }}>{registrationError}</p>}
    <form style={{margin: '0 auto', maxWidth: '600px' }} onSubmit={handleSubmit}>
      <h2>Регистрация пользователя</h2>
      <Label style={{width: '90%'}}>
        Логин пользователя:
        <Input type="text" name="username" value={formData.username} onChange={handleInputChange} />
      </Label>
      <br />
      <Label style={{width: '90%'}}>
        Пароль:
        <Input type="text" name="password" value={formData.password} onChange={handleInputChange} />
      </Label>
      <br />
      <Label style={{width: '90%'}}>
        Имя пользователя:
        <Input type="text" name="customer_name" value={formData.customer_name} onChange={handleInputChange} />
      </Label>
      <br />
      <Label style={{width: '90%'}}>
        Мобильный номер Ватсап:
        <Input type="text" name="mobile_number" value={formData.mobile_number} onChange={handleInputChange} />
      </Label>
      <br />
      <Label style={{width: '90%'}}>
        Адрес доставки:
        <Input type="text" name="address_for_order" value={formData.address_for_order} onChange={handleInputChange} />
      </Label>
      <br />
      <Label style={{width: '90%'}}>
        Комментарии к заказу:
        <Input type="text" name="comment_for_order" value={formData.comment_for_order} onChange={handleInputChange}/>
      </Label>
      <br />
      <Label style={{width: '90%'}}>
        Комментарии по доставке:
        <Input type="text" name="comment_for_delivery" value={formData.comment_for_delivery} onChange={handleInputChange} />
      </Label>
      <br />
      {usernameExists && <p style={{ color: 'red' }}>Это логин пользователя уже занято.</p>}
      <Button className="btn-success" type="submit">Регистрировать</Button>
    </form>
    </div>
  );
};

export default CustomerRegistrationForm;
