import {useEffect, useState} from "react";
import axios from "axios";
import { API_CUSTOMER } from "../../../hook";

export const CustomerLogout = () => {

    useEffect(() => {
        (async () => {
            try {
                const {data} = await axios.post(`${API_CUSTOMER}/logout/`,{
                    refresh_token:localStorage.getItem('refresh_token')
                } ,{headers: {
                    'Content-Type': 'application/json'
                }}, {withCredentials: true});
                localStorage.clear();
                axios.defaults.headers.common['Authorization'] = null;
                window.location.href = '/login'
            } catch (e) {
                console.log('logout not working')
            }
        })();
    }, []);

    return (
        <div></div>
    )
}