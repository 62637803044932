import React, { Fragment } from 'react';
import RegistrationForm from './RegistrationForm';
import AdministratorList from './AdministratorList';
import AdminHeader from '../AdminHeader';

export default function Owner() {
    return (
    <Fragment>
        <AdminHeader />
        <RegistrationForm />
        <AdministratorList />
    </Fragment>
    );
}
