import React from 'react'
import './Customer.css';
import CustomerHeader from './CustomerHeader';
import CustomerBody from './CustomerBody';

export default function Customer() {
  return (
    <div className="main-background">
        <CustomerHeader />
        <CustomerBody />
    </div>
  )
}
 