import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import ProductForm from "./ProductForm";

class NewProductModal extends Component {
  state = {
    modal: false
  };

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };
 
  render() {
    const create = this.props.create;

    var title = "Изменить Продукт";
    var button = <Button onClick={this.toggle}>Изменить</Button>;
    if (create) {
      title = "Создать Новый Продукт";

      button = (
        <Button
          color="success"
          className="float-right"
          onClick={this.toggle}
          style={{ minWidth: "200px" }}
        >
          Cоздать Продукт
        </Button>
      );
    }

    return (
      <Fragment>
        {button}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>

          <ModalBody>
            <ProductForm
              product_pk={this.props.product_pk}
              resetState={this.props.resetState}
              toggle={this.toggle}
              product={this.props.product}
            />
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default NewProductModal;