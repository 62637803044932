import {useEffect, useState} from "react";
import axios from "axios";
import { API_URL } from "../../../hook";

export const Home = () => {
    const [username, setUsername] = useState('');

    useEffect(() => {
        if(localStorage.getItem('access_token') === null){
            window.location.href = '/a-login'  
        }
        else{
            (async () => {
            try {
                const {data} = await axios.get(`${API_URL}/home/`, {
                headers: {
                  'Content-Type': 'application/json',
                }
              });

              setUsername(data.username);
            } catch (e) {
                console.log('not auth')
            }
        })()};
    }, []);



    return <div className="form-signin mt-5 text-center">
        <h3>Hi {username}</h3>
        
    </div>
}