import React, { Component } from 'react'
import AdminHeader from '../AdminHeader'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from 'reactstrap';
import { API_ADMIN } from '../../../hook';
import axios from 'axios';
import ReactPaginate from 'react-paginate';

export default class AnalyticOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
        data: [],
        selectedOption: 'last_day',
        dropdownOpen: false,
        currentPage: 0,
        pageCount: 0,
    }
  }

  componentDidMount() {
    this.fetchData(0);
  }

  fetchData(page) {
    axios.get(`${API_ADMIN}/product-quantities/${this.state.selectedOption}/?page=${page + 1}`)
      .then(response => {
        this.setState({ 
          data: response.data,
          pageCount: response.data.page_count, // Adjust based on your backend pagination logic
          currentPage: page,
        });
        console.log('www', `${API_ADMIN}/product-quantities/${this.state.selectedOption}/?page=${page}`);
        console.log('qqq', this.state.pageCount);
      })
      .catch(error => {
        console.error('Error fetching product quantities: ', error);
      });
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleOptionSelect = selectedOption => {
    this.setState({ selectedOption, currentPage: 0 }, () => {
      this.fetchData(0);
    });
  };

  handlePageClick = (selectedPage) => {
    this.fetchData(selectedPage.selected);
  };

  translate = key => {
    // Simple translation function, replace with your translations
    const translations = {
      'last_day': 'За этот день',
      'last_10_days': 'Последние 10 дней',
      'last_month': 'За этот месяц',
      'all_time': 'За все время',
    };

    return translations[key] || key; // Default to the key if no translation found
  };

  render() {
    const { data, selectedOption, dropdownOpen, currentPage, pageCount } = this.state;

    return (
      <>
        <AdminHeader />
        <h1 style={{textAlign: "center"}}>Аналитика продуктов</h1>
        <>
        <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle caret>
            {this.translate(selectedOption)}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.handleOptionSelect('last_day')}>{this.translate('За этот день')}</DropdownItem>
            <DropdownItem onClick={() => this.handleOptionSelect('last_10_days')}>{this.translate('Последние 10 дней')}</DropdownItem>
            <DropdownItem onClick={() => this.handleOptionSelect('last_month')}>{this.translate('За этот месяц')}</DropdownItem>
            <DropdownItem onClick={() => this.handleOptionSelect('all_time')}>{this.translate('За все время')}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <h3>Итого: {data.total_sum ? data.total_sum : 0}</h3>
        <Table dark bordered hover striped>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Наименование</th>
                    <th>Количество</th>
                    <th>Сумма</th>
                </tr>
            </thead>
            <tbody>
            {!data || data.length <= 0 ? (
              <tr>
                <td colSpan="6" align="center">
                  <b>Пусто</b>
                </td>
              </tr>
              ) : (
            data.items.map((item, index) => (
              <tr key={item.id}>
                  <td>{index+1}</td>
                  <td>{item.product_name}</td>
                  <td>{item.total_quantity}</td>
                  <td>{item.total_price}</td>
              </tr>
            )))}
            </tbody>
        </Table>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
            breakLabel={'...'}
            breakClassName={'break-me'}
            disableInitialCallback={true}
            pageLinkClassName={'page-link custom-page-link'}
            previousLinkClassName={'page-link custom-previous-link'}
            nextLinkClassName={'page-link custom-next-link'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
          />
        </div>

        </>
      </>
    )
  }
}
