import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_CUSTOMER } from '../../../hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../../../App.css';
import whats from '../../../assets/whats.png';
const ProductSearch = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Fetch products from the backend when searchTerm changes
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_CUSTOMER}/product-search/?search=${searchTerm}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error searching products:', error);
      }
    };

    // Only fetch products when searchTerm is not empty
    if (searchTerm.trim() !== '') {
      fetchProducts();
    } else {
      // If searchTerm is empty, fetch the initial set of products
      fetchInitialProducts();
    }
  }, [searchTerm]);

  const fetchInitialProducts = async () => {
    try {
      const response = await axios.get(`${API_CUSTOMER}/five-product/`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const discount = (product) => {
    if (product.price_by_discount && product.is_discount_by_price){
      return product.price_by_discount;
    } else {
      return product.price
    }
  }

  const line_through_discount = (product) => {
    if (product.price_by_discount && product.is_discount_by_price){
      return `${product.price}тг `
    } else {
      return null
    }
  }

  return (
    <div className='main-page side-margin'>
      <div style={{marginBottom: '10px'}}>
        <input
          style={{width: 'calc(100% - 80px)', marginRight: '10px', marginLeft: '10px'}}
          type="text"
          placeholder="Поиск товара"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div style={{float: 'right', 
          backgroundColor: 'rgb(21, 115, 71)',
          borderRadius: '50%',
          padding: '8px',
          cursor: 'pointer',
          display: 'flex',
          width: '25px',
          height: '25px',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '20px',
          marginTop: '2px'
        }}>
          <a href='/' style={{color: 'white'}}>
            <FontAwesomeIcon icon={faTimes} />
          </a>
        </div>

      </div>
      <div>
        {products.length === 0 ? (
          <div style={{ marginRight: '10px', marginLeft: '10px'}}>
            Какой товар вы хотели видеть в нашем магазине?  
              <a style={{}} href='https://wa.me/+77750035529'>
                <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#368B1C', width: '25px', height: '25px', marginLeft: '5px' }} />
              </a>

          </div>
        ) : (
          <div>
            {products.map((product) => (

                  <div style={{display: "flex", marginBottom: "30px"}} key={product.pk}>
                  <div style={{float: "left", marginLeft:"10px"}}>
                    <a href={`product-detail/${product.pk}`}>
                    <img className="product-image" 
                        style={{height: "138px", width: "138px", borderRadius: "5%"}} 
                        src={product.image}
                        alt={product.name}
                    />
                    </a>
                    </div>
                  <div className="product-details" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div className="product-description" style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                      {product.description}
                      </div>

                    <div style={{ display: '', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                      <div className="product-price" style={{ whiteSpace: 'pre-wrap'}}>
                        <span className='color-orange' 
                          style={{textDecorationLine: "line-through", textDecorationColor: "black", paddingRight: '5px'}}
                          >
                          {line_through_discount(product)}
                        </span>
                        <span className="color-green">{discount(product)}тг</span>
                      </div>

                  {/* Simplify the Add/Remove button logic */}
                  {/* <div className="d-flex" style={{display: 'flex', alignItems: 'center', fontSize: '25px'}}>
                      <FontAwesomeIcon icon={faPlus} onClick={() => this.handleAddToCart(product.pk)} className='plus-minus'/>
                      {cart.find(item => item.pk === product.pk) && (
                      <div>
                        <span style={{marginRight: "10px", marginLeft: "10px", fontSize: ""}}> {productQuantities[product.pk] || 0} </span>
                        <FontAwesomeIcon onClick={() => this.handleRemoveFromCart(product.pk)} icon={faMinus} className='plus-minus'/>
                      </div>
                    )}
                  </div> */}

                </div>
                  </div>
                  <div>
                    {/* <hr style={{ borderTop: '5px solid red', fontWeight: 'bold', width: '300px'}}/> */}
                  </div>
                </div>

            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductSearch;
