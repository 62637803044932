import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../hook';
import { Button, Input, Label } from 'reactstrap';

const RegistrationForm = () => {
  // State for form fields
  const [formData, setFormData] = useState({
    username: '',
    password1: '',
  });

  // State for success message
  const [registrationSuccess, setRegistrationSuccess] = useState(null);

  // State for registration error
  const [registrationError, setRegistrationError] = useState(null);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to your Django backend
      await axios.post(`${API_URL}/register/`, formData);

      setRegistrationSuccess('Администратор создан!');
      setFormData({
        username: '',
        password1: '',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      setTimeout(() => {
        setRegistrationSuccess(null);
        setRegistrationError(null);
      }, 5000);
    } catch (error) {
// Handle registration error
      setRegistrationError('Ошибка при создании администратора');
    }
  };

  return (
    <div style={{textAlign: "center"}}>
    {registrationSuccess && <p style={{ color: 'green' }}>{registrationSuccess}</p>}
      {registrationError && <p style={{ color: 'red' }}>{registrationError}</p>}
    <form onSubmit={handleSubmit}>
      <h2>Cоздание администратора</h2>
      <Label>
        Логин администратора:
        <Input type="text" name="username" value={formData.username} onChange={handleInputChange} />
      </Label>
      <br />
      <Label>
        Пароль:
        <Input type="text" name="password1" value={formData.password1} onChange={handleInputChange} />
      </Label>
      <br />
      <Button className="btn-success" type="submit">Cоздать администратора</Button>
    </form>
    </div>
  );
};

export default RegistrationForm;
