import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter } from "reactstrap";

import axios from "axios";
import { API_ADMIN } from "../../../hook";

export class BannerRemove extends Component {
  state = {
    modal: false,
  };

componentDidMount() {
  if (this.props.banner) {
    const { pk, image } = this.props.banner;
    this.setState({ pk, image });
  }
}

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteBanner = pk => {
    axios.delete(`${API_ADMIN}/banner/` + pk).then(() => {
      this.props.resetState();
      this.toggle();
    }).catch(error => {
      // Handle the error in a way that suits your application
      console.error("Error fetching delete banner on administrator page:", error);
      // You might choose to set an error state, show a user-friendly message, or log the error
    });
  };

  render() {
    return (
      <Fragment>
        <Button color="danger" onClick={() => this.toggle()}>
          Удалить
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Удалить {this.props.image}?
          </ModalHeader>

          <ModalFooter>
            <Button type="button" onClick={() => this.toggle()}>
              Отказать
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={() => this.deleteBanner(this.props.pk)}
            >
              Да
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
