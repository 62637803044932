// prod from django open server

export const API_ADMIN = 'https://back.seoul-station.kz/api/administrator';
export const API_CUSTOMER = 'https://back.seoul-station.kz/api/customer';
export const API_URL = 'https://back.seoul-station.kz';



// dev

//export const API_ADMIN = 'http://127.0.0.1:8080/api/administrator';
//export const API_CUSTOMER = 'http://127.0.0.1:8080/api/customer';
//export const API_URL = 'http://127.0.0.1:8080';


// export const API_ADMIN = 'http://45.159.250.90:8000/api/administrator';
// export const API_CUSTOMER = 'http://45.159.250.90:8000/api/customer';
// export const API_URL = 'http://45.159.250.90:8000';
