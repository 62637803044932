import axios from 'axios';
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { API_ADMIN } from '../../../hook';

export default class BannerForm extends Component {
    banner_url = `${API_ADMIN}/banner/`
    
    static propTypes = {
      banner: PropTypes.object,
      resetState: PropTypes.func.isRequired,
      toggle: PropTypes.func.isRequired,
    };

    state = {
        pk: 0,
        image: null,
    };

    componentDidMount() {
      if (this.props.banner) {
        const { pk, image } = this.props.banner;
        this.setState({ pk, image });
      }
    }

    onChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };
  
    handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        this.setState({ image: file });
      }
    };
    
    createBanner = (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("image", this.state.image);
      axios.post(this.banner_url, formData, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',  // Include if your request involves credentials
     }).then(() => {
        this.props.resetState();
        this.props.toggle();
      }).catch(error => {
        console.error("Error fetching create banner on administrator page:", error);
      });
    };
  
    editBanner = (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("image", this.state.image);
      axios.put(this.banner_url + this.state.pk, formData).then(() => {
        this.props.resetState();
        this.props.toggle();
      }).catch(error => {
        console.error("Error fetching edit banner on administrator page:", error);
      });
    };
  
    defaultIfEmpty = value => {
      return value === "" ? "" : value;
    };

  render() {
    return (
      <Form onSubmit={this.props.banner ? this.editBanner : this.createBanner}>
        <FormGroup>
          <Label for="image">Картинка:</Label>
          <Input
            type="file"
            image="image"
            accept="image/*"
            content="multipart/form-data"
            onChange={this.handleFileChange}
            required
          />
        </FormGroup>
        <Button type="submit">{this.props.banner ? "Изменить" : "Создать"}</Button>
      </Form>
    )
  }
}
