import React from 'react'
import Product from './Product'
import { useParams } from 'react-router-dom'
 
export default function ProductNavigator() {
  const params = useParams();
    return (
    <div><Product product_pk={params.product_pk} /></div>
  )
}
