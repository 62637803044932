import React, { Component } from 'react'
import BannerList from './main/BannerList'
import ProductList from './main/ProductList'

export default class CustomerBody extends Component {
  render() {
    return (
      <div>
        <div style={{ maxWidth: "800px", margin: "0 auto" }}>
          <div>
            <BannerList />
          </div>
          <div>
            <ProductList />
          </div>
        </div>
      </div>
    )
  }
}
