import React, { Component } from 'react'
import { API_CUSTOMER } from '../../../hook'
import axios from 'axios';
import './BannerList.css'; 

export default class BannerList extends Component {      
    constructor(props) {
        super(props);

        this.state = {
          data: null,
          currentIndex: 0,
        };

        this.intervalId = null;
    }
    
    componentDidMount() {
      this.fetchBannerData();

      this.startAutoScroll();
    }

    componentWillUnmount() {
      this.stopAutoScroll();
    }
  
    startAutoScroll = () => {
      this.intervalId = setInterval(this.scrollNext, 5000); // Change the interval as needed (e.g., 3000 milliseconds or 3 seconds)
    };
  
    stopAutoScroll = () => {
      clearInterval(this.intervalId);
    };

    fetchBannerData = () => {
        axios
          .get(`${API_CUSTOMER}/banner/`)
          .then(response => {
            this.setState({
              data: response.data,
              loading: false,
            });
          })
          .catch(error => {
            this.setState({
              loading: false,
              error: error.message,
            });
          });
    };
      
    scrollNext = () => {
      const { currentIndex, data } = this.state;
      const nextIndex = (currentIndex + 1) % data.length;
      this.setState({ currentIndex: nextIndex });
    };
      
      render() {
        const { data, currentIndex } = this.state;
        return (
          <div className="banner-list-container"     
            onMouseEnter={this.stopAutoScroll} onMouseLeave={this.startAutoScroll} 
          >
            {data && (
              <div
                className="banner-list" 
                style={{ position: 'relative', width: '100%', display: 'flex', boxSizing: 'content-box', 
                  transform: `translateX(-${currentIndex%2*50}%)`, transition: 'transform 1s ease', 
                  }}>{console.log('cur', currentIndex)}
                {data.map(item => (
                  <div className="banner-item" 
                  style={{
                    display: 'flex', marginRight: "10px", flexDirection: 'column', boxSizing: 'border-box', cursor: 'pointer', 
                    listStylePosition: 'outside', scrollBehavior: 'smooth'
                  }} key={item.pk}>
                    <img className="banner-image" style={{height:"148px", width:"280px", borderRadius:"5%"}} src={item.image} alt={item.pk}/>
                    </div>
                ))}
              </div>
            )}
            
          </div>
        );
    }
}

