import axios from 'axios';
import React, { Component } from 'react'
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { API_ADMIN } from '../../../hook';

export default class CategoryForm extends Component {
    category_url = `${API_ADMIN}/category/`
    
    state = {
        pk: 0,
        name: ""
    };
   
    componentDidMount() {
      if (this.props.category) {
        const { pk, name } = this.props.category;
        this.setState({ pk, name });
      }
    }
  
    onChange = e => {
      this.setState({ [e.target.name]: e.target.value });
    };
  
    createCategory = e => {
      e.preventDefault();
      axios.post(this.category_url, this.state, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',  // Include if your request involves credentials
     }).then(() => {
        this.props.resetState();
        this.props.toggle();
      }).catch(error => {
        // Handle the error in a way that suits your application
        console.error("Error create category on administrator page:", error);
        // You might choose to set an error state, show a user-friendly message, or log the error
      });
      
    };
  
    editCategory = e => {
      e.preventDefault();
      axios.put(this.category_url + this.state.pk, this.state).then(() => {
        this.props.resetState();
        this.props.toggle();
      });
    };
  
    defaultIfEmpty = value => {
      return value === "" ? "" : value;
    };

    // buttonName = () => {
    //   return this.props.category ? Изменить : Создать;
    // }

  render() {
    return (
      <Form onSubmit={this.props.category ? this.editCategory : this.createCategory}>
        <FormGroup>
          <Label for="name">Наименование:</Label>
          <Input
            type="text"
            name="name"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.name)}
            required
          />
        </FormGroup>
        <Button>{this.props.category ? "Изменить" : "Создать"}</Button>
      </Form>
    )
  }
}
