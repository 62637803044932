import './App.css';
import Admin from './components/administrator/Admin';
import Banner from './components/administrator/banner/Banner';
import Category from './components/administrator/category/Category';
import { Route, Routes } from 'react-router-dom';
import ProductNavigator from './components/administrator/product/ProductNavigator';
import React from 'react';
import Customer from './components/customer/Customer';
import Cart from './components/customer/main/Cart';
import OrderForm from './components/customer/main/OrderForm';

// import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Login} from "./components/administrator/auth/login";
import {Home} from "./components/administrator/analytics/Home";
import {Logout} from './components/administrator/auth/logout';
// import RegistrationForm from './components/administrator/auth/RegistrationForm';
// import AdministratorList from './components/administrator/auth/AdministratorList';
import Owner from './components/administrator/auth/Owner';
import { CustomerLogin } from './components/customer/auth/login';
import { CustomerLogout } from './components/customer/auth/logout';
import CustomerRegistrationForm from './components/customer/auth/RegistrationForm';
import ProfileUpdateView from './components/customer/auth/ProfileUpdateView';
import CustomerList from './components/administrator/analytics/CustomerList';
import AnalyticOrder from './components/administrator/analytics/AnalyticOrder';
import OrderList from './components/customer/auth/OrderList';
import ProductDetail from './components/customer/main/ProductDetail';
import ProductSearch from './components/customer/main/ProductSearch';

function App() {

  return (
    <Routes>
        {/* ADMIN SIDE */}
        <Route path="/admin/" element={<Admin />} />
        <Route path="/admin/category/" element={<Category />} />
        <Route path='/admin/banner/' element={<Banner />} />
        <Route path="/admin/product/:product_pk" element={<ProductNavigator />}/>

        {/* Auth Owner Admin */}
        <Route path="/a-n/" element={<Home/>}/>
        <Route path="/a-login/" element={<Login/>}/>
        <Route path="/a-logout/" element={<Logout/>}/>
        <Route path="/owner/" element={<Owner />} />

        <Route path="/customer-list/" element={<CustomerList />}/>
        <Route path="/analytic-order/" element={<AnalyticOrder />}/>
                
        {/* CUSOMER SIDE */}
        <Route path="/" element={<Customer />} />
        <Route path="/product-detail/:product_pk" element={<ProductDetail />} />
        <Route path="/product-search/" element={<ProductSearch />} />

        <Route path="/cart/" element={<Cart />} />
        <Route path="/make-order/" element={<OrderForm />} />

        {/* Auth Customer */}
        <Route path="/login/" element={<CustomerLogin />} />
        <Route path="/logout/" element={<CustomerLogout />} />
        <Route path="/register/" element={<CustomerRegistrationForm />} />
        <Route path="/update/" element={<ProfileUpdateView />} />
        <Route path="/order-list/" element={<OrderList />}/>
    </Routes>
  );
}

export default App;
 