import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_CUSTOMER } from '../../../hook';
import { useParams } from 'react-router-dom';
import './ProductList.css';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ProductDetail = () => {
  const { product_pk } = useParams();
  const [product, setProduct] = useState(null);
  let [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${API_CUSTOMER}/get-product/${product_pk}`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, [product_pk]);

  const discount = (product) => {
    if (product.price_by_discount && product.is_discount_by_price){
      return product.price_by_discount;
    } else {
      return product.price
    }
  }

  const line_through_discount = (product) => {
    if (product.price_by_discount && product.is_discount_by_price){
      return `${product.price}тг `
    } else {
      return null
    }
  }

  let handleAddToCart = () => {
    console.log('aaaaa');
    console.log('dt', product_pk);
    const existingProduct = cart.find(item => item.pk == product_pk) || '';
    console.log('ee', existingProduct.quantity)
  
    if (existingProduct) {
      // If the product is already in the cart, update its quantity
      const updatedCart = cart.find(item => item.pk == product_pk).quantity += 1;
      console.log('updcart');
      setCart(updatedCart);
    } else {  
      console.log('new prd cart');
      // If the product is not in the cart, add a new item
      const newCartItem = { ...product, quantity: 1 };
      console.log('new', newCartItem.product);
      const updatedCart = [...cart, newCartItem];
      setCart(updatedCart);
      cart = updatedCart;
      console.log('u', updatedCart[0]);
      console.log('u', updatedCart[1]);
      console.log('setted');
      console.log('cc', cart);
      
      console.log('ucc', cart)
      // console.log(cart[0]);
      // console.log(cart[1]);
      // console.log(cart);
    }
  
    // Update localStorage with the new cart data
    localStorage.setItem('cart', JSON.stringify(cart));
  };
  

  return (
    <div style={{ 
      backgroundColor: "#fff", maxWidth: "800px", margin: "0 auto", paddingTop: '10px'
      }}>
      <div style={{float: 'right', 
        backgroundColor: '#368B1C',
        borderRadius: '50%',
        padding: '8px',
        cursor: 'pointer',
        display: 'flex',
        width: '25px',
        height: '25px',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '20px',
        marginBottom: '10px',
        }}>
          <a href='/' style={{color: 'white'}}>
            <FontAwesomeIcon icon={faTimes} />
          </a>
        </div>
      {product ? (
        <div style={{textAlign: 'center'}}>
          <div>
            <img src={product.image} style={{height: '100%', width: '100%'}} />
          </div>
          <div>
            {product.description}
          </div>
          <div className="product-price" style={{ whiteSpace: 'pre-wrap'}}>
            <span className='color-orange' 
              style={{textDecorationLine: "line-through", textDecorationColor: "black", paddingRight: '5px'}}
              >
              {line_through_discount(product)}
            </span>
            <span className="color-green">{discount(product)}тг</span>
            </div>
        </div>
      ) : (
        <p>...</p>
      )}
      <div style={{textAlign: 'center'}}>
        <a href='/'>
          <Button className='btn btn-success' onClick={() => handleAddToCart()} >Добавить в козинку</Button>
        </a>
      </div>
      
      <div style={{height: '20px'}}></div>

    </div>
  );
};

export default ProductDetail;