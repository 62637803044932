import React, { Component } from 'react';
import AdminHeader from '../AdminHeader';
import { API_ADMIN } from '../../../hook';
import axios from 'axios';
import { Table, Spinner } from 'reactstrap'; // Import Spinner for loading indicator
import ReactPaginate from 'react-paginate';

export default class CustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerList: [],
      pageCount: 0,
      currentPage: 0,
      isLoading: true, // Add loading state
    };
  }

  componentDidMount() {
    this.fetchCustomer(0);
  }

  fetchCustomer(page) {
    this.setState({ isLoading: true }); // Set loading to true before making the request

    axios.get(`${API_ADMIN}/customer-list/?page=${page + 1}`)
      .then(response => {
        this.setState({
          customerList: response.data.results,
          pageCount: Math.ceil(response.data.count / 50), // Adjust based on your backend pagination logic
          currentPage: page,
          isLoading: false, // Set loading to false on success
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ isLoading: false }); // Set loading to false on error
      });
  }

  handlePageClick = (selectedPage) => {
    this.fetchCustomer(selectedPage.selected);
  };

  render() {
    const { pageCount, currentPage, isLoading, customerList } = this.state;

    return (
      <>
        <AdminHeader />
        <h1 style={{ textAlign: "center" }}>Список Покупателей</h1>

        {isLoading ? (
          <Spinner color="primary" /> // Show a loading spinner while fetching data
        ) : (
          <Table dark bordered hover striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Имя Пользователя</th>
                <th>Номер Телефона</th>
                <th>Адрес</th>
                <th>Комментарии к заказу</th>
                <th>Комментарии к доставке</th>
              </tr>
            </thead>
            <tbody>
              {customerList.length === 0 ? (
                <tr>
                  <td colSpan="6" align="center">
                    <b>Пусто</b>
                  </td>
                </tr>
              ) : (
                customerList.map((customer, index) => (
                  <tr key={customer.pk}>
                    <td>{index + 1}</td>
                    <td>{customer.customer_name}</td>
                    <td>{customer.mobile_number}</td>
                    <td>{customer.address_for_order}</td>
                    <td>{customer.comment_for_order}</td>
                    <td>{customer.comment_for_delivery}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
            breakLabel={'...'}
            breakClassName={'break-me'}
            disableInitialCallback={true}
            pageLinkClassName={'page-link custom-page-link'}
            previousLinkClassName={'page-link custom-previous-link'}
            nextLinkClassName={'page-link custom-next-link'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
          />
        </div>
      </>
    );
  }
}
