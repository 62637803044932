import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { API_ADMIN } from "../../../hook";
import axios from "axios";


export default class DiscountProduct extends Component {
  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };
  state = {
      modal: false,
      pk: 0,
      price_by_discount: null,
      is_discount_by_price: false,
      is_discount_by_quantity: false,
      discount_by_quantity: null,
  };
  componentDidMount() {
    if (this.props.product) {
      const { pk, price_by_discount, discount_by_quantity, is_discount_by_price, is_discount_by_quantity } = this.props.product;
      this.setState({ pk, price_by_discount, discount_by_quantity, is_discount_by_price, is_discount_by_quantity });
    }
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  defaultIfEmpty = value => {
    return value === "" ? "" : value;
  };
  make_discount = e => {
    e.preventDefault();
    const formData = new FormData();
    if (this.state.price_by_discount !== null) {
      formData.append("price_by_discount", this.state.price_by_discount);
    }
    if (this.state.discount_by_quantity !== null) {
      formData.append("discount_by_quantity", this.state.discount_by_quantity);
    }
    axios.patch(`${API_ADMIN}/discount/` + this.state.pk, formData)
    .then(() => {
      this.setState({ successMessage: "Изменения применены!" });
        // Reset success message after 5 seconds
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000); 
      this.props.resetState();
      // this.toggle();
    })
    .catch((error) => {
      console.error("Error making discount on administrator page:", error);
    });
  }

  handleToggleDiscountByPrice = () => {
    const newIsDiscountByPrice = !this.state.is_discount_by_price;
    this.setState((prevState) => ({
      is_discount_by_price: !prevState.is_discount_by_price,
      is_discount_by_quantity: false, // Ensure the other button is secondary
    }));
    // Make API request to update is_discount_by_price on the server
    axios
      .patch(`${API_ADMIN}/discount-by-price/` + this.state.pk, {
        is_discount_by_price: newIsDiscountByPrice,
      })
      .then(() => {
        this.setState({ successMessage: "Изменения применены!" });
        // this.toggle();
        setTimeout(() => {
          this.setState({ successMessage: null });
        }, 3000);
      })
      .catch((error) => {
        console.error("Error updating discount by price:", error);
      });
  };

  handleToggleDiscountByQuantity = () => {
    const newIsDiscountByQuantity = !this.state.is_discount_by_quantity;
    this.setState((prevState) => ({
      is_discount_by_quantity: !prevState.is_discount_by_quantity,
      is_discount_by_price: false, // Ensure the other button is secondary
    }));
    // Make API request to update is_discount_by_quantity on the server
    axios
      .patch(`${API_ADMIN}/discount-by-quantity/` + this.state.pk, {
        is_discount_by_quantity: newIsDiscountByQuantity,
      })
      .then(() => {
        this.setState({ successMessage: "Изменения применены!" });
        // this.toggle();
        setTimeout(() => {
          this.setState({ successMessage: null });
        }, 3000);
      })
      .catch((error) => {
        console.error("Error updating discount by quantity:", error);
      });
  };

  render() {
    const buttonStyleByPrice = this.state.is_discount_by_price ? "success" : "secondary";
    const buttonStyleByQuantity = this.state.is_discount_by_quantity ? "success" : "secondary";
    const isDiscount = (this.state.is_discount_by_price || this.state.is_discount_by_quantity) ? "success" : "secondary";

    var button = <Button color={isDiscount} onClick={this.toggle}>Скидкa</Button>;
    
      return (
        <Fragment>
          {console.log(`${isDiscount}`)}
        {button}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Скидки</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.make_discount}>
              {this.state.successMessage && (
                <Alert color="success" className="mt-2 mb-2">
                  {this.state.successMessage}
                </Alert>
              )}
              <FormGroup>
                <Label for="name">Скидка Цена:</Label>
                <Input 
                  type="number"
                  name="price_by_discount"
                  onChange={this.onChange}
                  value={this.defaultIfEmpty(this.state.price_by_discount)} 
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">Скидка при количестве:</Label>
                <Input 
                  type="number"
                  name="discount_by_quantity"
                  onChange={this.onChange}
                  value={this.defaultIfEmpty(this.state.discount_by_quantity)} 
                />
              </FormGroup>
              <FormGroup>
                <Button color={buttonStyleByPrice} onClick={this.handleToggleDiscountByPrice}>
                  Скидка по цене
                </Button>
                <span style={{margin: "10px"}}></span>
                <Button color={buttonStyleByQuantity} onClick={this.handleToggleDiscountByQuantity}>
                  Скидка по количеству
                </Button>
              </FormGroup>
              <div>
                <Button>Назначить</Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}
