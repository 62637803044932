import React, { Component } from 'react'
import AdminHeader from '../AdminHeader'
import CategoryList from './CategoryList';
import axios from 'axios';
import NewCategoryModal from './NewCategoryModal';
import { API_ADMIN } from '../../../hook';


class Category extends Component {
  state = {
    categories: []
  };

  componentDidMount() {
    this.resetState();
  }
  
  getCategories = () => {
    axios.get(`${API_ADMIN}/category/`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',  // Include if your request involves credentials
   })
      .then(res => this.setState({ categories: res.data }))
      .catch(error => {
        // Handle the error in a way that suits your application
        console.error("Error fetching list categories on administrator page:", error);
        // You might choose to set an error state, show a user-friendly message, or log the error
      });
  };
  resetState = () => {
    this.getCategories();
  };


  render() {
    return (
      <div>
        <AdminHeader />
        <div style={{textAlign: "center"}} className="mt-1 mb-1">
            <NewCategoryModal create={true} resetState={this.resetState} />
            </div>
        <div className="m-auto" style={{textAlign: "center", maxWidth: "90%"}}>
              <CategoryList categories={this.state.categories} resetState={this.resetState} />
        </div>
      </div>
    )
  }
}

export default Category;