import React, { Component } from 'react'
import AdminHeader from '../AdminHeader'
import BannerList from './BannerList';
import axios from 'axios';
import { API_ADMIN } from '../../../hook';
import NewBannerModal from './NewBannerModal';

export default class Banner extends Component {
  state = {
    banners: []
  };

  componentDidMount() {
    this.resetState();
  }
  
  getBanners = () => {
    axios.get(`${API_ADMIN}/banner/`).then(res => this.setState({banners: res.data }))
    .catch(error => {
      // Handle the error in a way that suits your application
      console.error("Error fetching list banners on administrator page:", error);
      // You might choose to set an error state, show a user-friendly message, or log the error
    });
  };
  resetState = () => {
    this.getBanners();
  };


  render() {
    return (
      <div>
        <AdminHeader />
        <div style={{textAlign: "center"}} class="mt-1 mb-1"><NewBannerModal create={true} resetState={this.resetState} /></div>
        <div className="m-auto" style={{textAlign: "center", maxWidth: "90%"}}>
            <BannerList banners={this.state.banners} resetState={this.resetState} />
        </div>
      </div>
    )
  }
}
