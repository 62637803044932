import React, { Component } from 'react'
import AdminHeader from '../AdminHeader'
import axios from 'axios';
import { API_ADMIN } from '../../../hook';
import NewProductModal from './NewProductModal';
import ProductList from './ProductList';

class Product extends Component {
  state = {
    products: []
  };

  componentDidMount() {
    this.resetState();
  }
   
  getProducts = () => {
    axios.get(`${API_ADMIN}/${this.props.product_pk}/product/`).then(res => this.setState({products: res.data }));
  };

  resetState = () => {
    this.getProducts();
  };


  render() {
    return (
      <div>
        <AdminHeader />
        <div style={{textAlign: "center"}} className="mt-1 mb-1">
          <NewProductModal product_pk={this.props.product_pk} create={true} resetState={this.resetState} />
        </div>
        <div class="m-auto" style={{textAlign: "center", maxWidth: "90%"}}>
          <ProductList product_pk={this.props.product_pk} products={this.state.products} resetState={this.resetState} />
        </div>
      </div>
    )
  }
}

export default Product;