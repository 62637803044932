import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Label, Input, Button } from 'reactstrap';
import { API_CUSTOMER } from '../../../hook';
import CustomerHeader from '../CustomerHeader'
const UpdateProfile = () => {
  const [profileData, setProfileData] = useState({
    customer_name: '',
    mobile_number: '',
    comment_for_order: '',
    address_for_order: '',
    comment_for_delivery: '',
  });

    // State for success message
  const [updateSuccess, setUpdateSuccess] = useState(null);

  useEffect(() => {
    axios.get(`${API_CUSTOMER}/update/`)
      .then(response => setProfileData(response.data))
      .catch(error => console.error('Error fetching profile data:', error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleUpdateProfile = () => {
    axios.put(`${API_CUSTOMER}/update/`, profileData)
      .then(response => {
        console.log('Profile updated successfully:', response.data);
        setUpdateSuccess('Вы обновили профиль!');
        setTimeout(() => {
          setUpdateSuccess(null);
        }, 5000);
      })
      .catch(error => {
        console.error('Error updating profile:', error);
      });
  };
  

  return (
    <div style={{textAlign: "center"}}>
      <CustomerHeader />
      {updateSuccess && <p style={{ color: 'green' }}>{updateSuccess}</p>}
      <h2>Обновить профиль</h2>
      <form style={{margin: '0 auto', maxWidth: '600px' }}>
        <div>
          <Label style={{width: '90%'}} for="customer_name">Имя пользователя:
            <Input type="text" name="customer_name" value={profileData?.customer_name} onChange={handleInputChange} />
          </Label>
        </div>
        <div>
          <Label style={{width: '90%'}} for="mobile_number">Мобильный номер Ватсап:
            <Input type="text" name="mobile_number" value={profileData?.mobile_number} onChange={handleInputChange} />
          </Label>
        </div>
        <div>
          <Label style={{width: '90%'}} for="address_for_order">Адрес доставки:
            <Input type="text" name="address_for_order" value={profileData?.address_for_order} onChange={handleInputChange} />
          </Label>
        </div>
        <div>
          <Label style={{width: '90%'}} for="comment_for_order">Комментарии к заказу:
            <Input type="text" name="comment_for_order" value={profileData?.comment_for_order} onChange={handleInputChange} />
          </Label>
        </div>
        <div>
          <Label style={{width: '90%'}} for="comment_for_delivery">Комментарии по доставке:
            <Input type="text" name="comment_for_delivery" value={profileData?.comment_for_delivery} onChange={handleInputChange} />
          </Label>
        </div>
        <Button color="primary" onClick={handleUpdateProfile}>Обновить</Button>
      </form>
    </div>
  );
};

export default UpdateProfile;
