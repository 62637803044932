import React, { Component } from 'react';
import OrderList from './OrderList';

export default class Order extends Component {
    render() {    
        return (
          <>
              <OrderList />
          </>
        )
  }
}
