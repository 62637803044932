import React, { Fragment } from 'react';
import './Admin.css';
import AdminHeader from './AdminHeader';
import Order from './order/Order';

export default function Admin() {
    return (
    <Fragment>
        <AdminHeader />
        <Order />
    </Fragment>
    );
}
