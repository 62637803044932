import React, { Component } from "react";
import { Table } from "reactstrap";
import { BannerRemove } from "./BannerRemove";
import NewBannerModal from "./NewBannerModal";


class BannerList extends Component {
  render() {
    const banners = this.props.banners;
    return (
      <Table dark bordered striped hover>
        <thead>
          <tr>
            <th>Баннеры</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!banners || banners.length <= 0 ? (
            <tr>
              <td colSpan="6" align="center">
                <b>Пусто</b>
              </td>
            </tr>
          ) : (
            banners.map(banner => (
              <tr key={banner.pk} style={{height: "400px"}}>
                <td style={{width: "80%"}}><img style={{width: "758px", height: "380px"}} src={banner.image} alt={banner.pk}/></td>
              <td align="center" style={{verticalAlign: "middle"}}>
                  <NewBannerModal
                    create={false}
                    banner={banner}
                    resetState={this.props.resetState}
                  />
                  &nbsp;&nbsp;
                  <BannerRemove
                    pk={banner.pk}
                    image={banner.image}
                    resetState={this.props.resetState}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    );
  }
}

export default BannerList;