import React, { Component } from "react";
import { Table } from "reactstrap";
import { Link } from 'react-router-dom';
import { CategoryRemove } from "./CategoryRemove";
import NewCategoryModal from "./NewCategoryModal";
 
class CategoryList extends Component {
  render() {
    const categories = this.props.categories;
    return (
      <Table dark bordered hover striped>
        <thead>
          <tr>
            <th>Категория</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!categories || categories.length <= 0 ? (
            <tr>
              <td colSpan="6" align="center">
                <b>Пусто</b>
              </td>
            </tr>
          ) : (
            categories.map(category => (
              <tr key={category.pk}>
                <td style={{width: "80%"}}>
                  <Link style={{textDecoration:"none", color: "white"}} to={`/admin/product/${category.pk}`} category={category}>{category.name}</Link>
                </td>
                <td align="center">
                  <NewCategoryModal
                    create={false}
                    category={category}
                    resetState={this.props.resetState}
                  />
                  &nbsp;&nbsp;
                  <CategoryRemove
                    pk={category.pk}
                    name={category.name}
                    resetState={this.props.resetState}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    );
  }
}

export default CategoryList;