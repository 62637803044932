  import React, { Component } from 'react'
  import { API_CUSTOMER } from '../../../hook'
  import axios from 'axios';
  import { Button, Label } from 'reactstrap';
  import { Link } from 'react-router-dom';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faPlus, faMinus, faShoppingBasket, faSearch } from '@fortawesome/free-solid-svg-icons';
  import './ProductList.css';
  import '../../../App.css';

  export default class ProductList extends Component {      
      constructor(props) {
          super(props);

          // Initialize selectedCategory from localStorage or set to -1 if not available
          const savedCategory = parseInt(localStorage.getItem('selectedCategory'), 10);
          const savedCategoryName = localStorage.getItem('selectedCategoryName');
      
          this.state = {
            selectedCategory: isNaN(savedCategory) ? -1 : savedCategory,
            categories: [],
            products: [],
            cart: JSON.parse(localStorage.getItem('cart')) || [],
            selectedCategoryName: savedCategoryName ? savedCategoryName : 'Все',
          };
      }

      componentDidMount() {
          // Fetch category data using Axios
          axios.get(`${API_CUSTOMER}/category/`)
            .then(response => {
              this.setState({ categories: response.data });
            })
            .catch(error => {
              console.error('Error fetching categories:', error);
            });
      
            // Fetch product data using Axios
            const { selectedCategory } = this.state;
            axios.get(`${API_CUSTOMER}/${selectedCategory}/product/`)
            .then(response => {
              this.setState({ products: response.data });
            })
            .catch(error => {
              console.error('Error fetching products:', error);
            });
        }

        componentDidUpdate() {
          // Update localStorage when productQuantities change
          localStorage.setItem('cart', JSON.stringify(this.state.cart));
        }

        handleCategoryClick = (categoryId, categoryName) => {
          this.setState({ selectedCategory: categoryId }, () => {
            localStorage.setItem('selectedCategory', categoryId);

            localStorage.setItem('selectedCategoryName', categoryName);
            this.state.selectedCategoryName = categoryName;
            axios.get(`${API_CUSTOMER}/${categoryId}/product/`)
              .then(response => {
                this.setState({ products: response.data });
              })
              .catch(error => {
                console.error('Error fetching products:', error);
              });
          });
        };
        
      
        handleShowAllClick = () => {
          // Reset selectedCategory to -1 to show all products
          localStorage.setItem('selectedCategoryName', 'Все');
          this.state.selectedCategoryName = 'Все';
          this.setState({ selectedCategory: -1 }, () => {
            localStorage.setItem('selectedCategory', -1);
            // Fetch all products
            axios.get(`${API_CUSTOMER}/${-1}/product/`)
              .then(response => {
                this.setState({ products: response.data });
              })
              .catch(error => {
                console.error('Error fetching all products:', error);
              });
          });
        };
        

        handleAddToCart = (productId) => {
          const productToAdd = this.state.products.find(product => product.pk === productId);

          if (productToAdd) {
            const existingCartItem = this.state.cart.find(item => item.pk === productId);
      
            if (existingCartItem) {
              // If the item is already in the cart, update the quantity
              const updatedCart = this.state.cart.map(item =>
                item.pk === productId ? { ...item, quantity: item.quantity + 1 } : item
              );
              this.setState({ cart: updatedCart });
              localStorage.setItem('cart', JSON.stringify(updatedCart));
            } else {
              // If the item is not in the cart, add it with quantity 1
              const updatedCart = [...this.state.cart, { ...productToAdd, quantity: 1 }];
              this.setState({ cart: updatedCart });
              localStorage.setItem('cart', JSON.stringify(updatedCart));
            } 
          }
        };
      
        handleRemoveFromCart = (productId) => {
          const existingCartItem = this.state.cart.find(item => item.pk === productId);
      
          if (existingCartItem) {
            const updatedCart = this.state.cart.map(item =>
              item.pk === productId ? { ...item, quantity: item.quantity - 1 } : item
            ).filter(item => item.quantity > 0);

            this.setState({ cart: updatedCart });
            localStorage.setItem('cart', JSON.stringify(updatedCart));
          }
        };
        calculateTotalPrice = () => {
          return this.state.cart.reduce((total, item) => total + this.handlePriceCalculation(item), 0);
        };
        handlePriceCalculation = (item) => {
          if (item.price_by_discount) {
            if (item.is_discount_by_price) {
              return item.quantity * item.price_by_discount;
            } else if (item.is_discount_by_quantity && item.discount_by_quantity) {
              if (item.discount_by_quantity <= item.quantity) {
                return item.quantity * item.price_by_discount;
              } else {
                return item.quantity * item.price;
              }
            } else {
              return item.quantity * item.price;
            }
          } else {
            return item.quantity * item.price;
          }
        }
        
        // End Cart
        
          discount = (product) => {
            if (product.price_by_discount && product.is_discount_by_price){
              return product.price_by_discount;
            } else {
              return product.price
            }
          }

          line_through_discount = (product) => {
            if (product.price_by_discount && product.is_discount_by_price){
              return `${product.price}тг `
            } else {
              return null
            }
          }
        
        render() {
          const { selectedCategory, categories, products, cart } = this.state;

          const buttonStyle = {
            backgroundColor: 'rgb(243, 244, 245)',  // Change this to the desired color for non-selected buttons
            color: 'black',
            marginLeft: '10px',
            overflow: 'auto',
          };
      
          const selectedButtonStyle = {
            backgroundColor: '#368B1C',  // Change this to the desired color for the selected button
            color: '#fff',
            marginLeft: '10px',
            overflow: 'auto',
          };

          return (
            <div style={{marginTop: "15px"}}>

              <div className='side-margin' style={{marginBottom: '15px', 
                  marginRight: '10px', marginLeft: '10px'
                }}> 

                {/*
                <a href='product-search'>
                    <input style={{width: '100%', height: '38px', borderRadius: '5px'}} placeholder='Поиск товара' />
                </a>
                */}

                {/*               
                 <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 1a5.5 5.5 0 0 1 4.383 8.823l3.896 3.9a.75.75 0 0 1-1.061 1.06l-3.895-3.9A5.5 5.5 0 1 1 6.5 1Zm0 1.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z" fill="#9398A1"></path></svg>
                 */}
              </div>

              <div style={{ display:"", overflowX: 'scroll', whiteSpace: "nowrap", flexWrap: 'nowrap', scrollBehavior: 'smooth'}}>

              <Button 
                className='category'
                style={selectedCategory === -1 ? selectedButtonStyle : buttonStyle} 
                onClick={this.handleShowAllClick}>
                  Все
              </Button>
                {categories.map(category => (
                  <Button
                    className='category'
                    key={category.pk}
                    style={selectedCategory === category.pk ? selectedButtonStyle : buttonStyle}
                    // style={{marginLeft: "10px", overflow:"auto"}}
                    onClick={() => this.handleCategoryClick(category.pk, category.name)}>
                    {category.name}
                  </Button>
                ))}
              </div>

              <div style={{paddingLeft: "10px", paddingTop: "20px", paddingBottom: "20px"}}>
                <span style={{backgroundColor: "rgb(243, 244, 245)", borderRadius: "10%", padding: "10px"}}>{this.state.selectedCategoryName}</span>
              </div>


              {/* product list */}

              <div style={{ marginTop: "10px", marginRight: '10px', marginLeft: '10px'}} class="product-grid">
                {products.map(product => (
                  <div style={{
                    paddingBottom: '10px',
                    marginBottom: "10px", borderStyle: 'solid',
                    borderWidth: '2px', borderColor: 'rgba(84, 2, 2, .5)',
                    borderRadius: '15px',
                    }}
                    className='product'
                    key={product.pk}
                    >
                    <div>
                      <a href={`product-detail/${product.pk}`}>

                      <img
                          className='product-image'
                          src={product.image}
                          alt={product.name}
                      />

                      </a>
                    </div>
                    <div className='product-detail-text'>
                    <div className="product-detail" style={{
                          justifyContent: 'space-between', fontSize: '16px'}}>
                          <div>
                            <div className="product-price" style={{ whiteSpace: 'pre-wrap', fontSize: '30px', paddingTop: '5px'}}>
                              <span className='color-orange'
                                style={{textDecorationLine: "line-through", textDecorationColor: "black", paddingRight: '5px'}}
                                >
                                {this.line_through_discount(product)}
                              </span>
                              <span className="color-green">{this.discount(product)}тг</span>
                            </div>
                          <a href={`product-detail/${product.pk}`} style={{textDecoration: 'none', color: 'inherit'}}>
                            <div className="product-description" style={{width: '100%', paddingLeft: '5px', fontSize: '22px'}}>
                                {product.description}
                            </div>
                          </a>
                    </div>
                    {/* Simplify the Add/Remove button logic */}
                    
                    <div style={{fontSize: '25px', paddingLeft: '5px'}} class="product-count-row">
                        <FontAwesomeIcon icon={faPlus} onClick={() => this.handleAddToCart(product.pk)} className='plus-minus'/>
                        {cart.find(item => item.pk === product.pk) && (
                        <span style={{marginTop: '-7px'}}>
                          <span style={{marginRight: "10px", marginLeft: "10px", fontSize: "40px", userSelect: 'none'}}>
                            {cart.find(item => item.pk === product.pk).quantity || 0}
                          </span>
                          <FontAwesomeIcon onClick={() => this.handleRemoveFromCart(product.pk)} icon={faMinus} className='plus-minus'/>
                        </span>
                      )}
                            </div>
                        </div>
                    </div>

                    <div>
                      {/* <hr style={{ borderTop: '5px solid red', fontWeight: 'bold', width: '300px'}}/> */}
                    </div>

                  </div>
                  
                ))}
              </div>
              
              <footer>
                <div style={{height: '70px'}}></div>
              </footer>

              {/* Display Shopping Cart and Total Price */}

              {(this.calculateTotalPrice() > 0) && (
                <Link className='color-green-background'
                style={{ color: 'white', textDecoration: 'none', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                position: 'fixed', 
                bottom: '15px', 
                right: '20px',
                minWidth:'125px', 
                height: '58px', 
                padding: '14px', 
                borderRadius: '100px', 
                zIndex: '10',
                textAlign: 'center', 
                
              

                // left: 'calc(100% - 15px - 125px)'
              }}
                to="/cart/">
                  <div
                    >
                    <FontAwesomeIcon icon={faShoppingBasket} size="2x" style={{ marginRight: '10px' }} />
                    <span style={{ marginLeft: '10px', fontSize: "30px" }}>{this.calculateTotalPrice()}тг</span>
                  </div>
                </Link>
              )}
            </div>
          );
        }
  }

